




































import { Component, Mixins } from "vue-property-decorator";
import FormCard from "@/components/form/FormCard.vue";
import BaseForm from "@/components/form/BaseForm";
import RulesMixin from "@/mixins/RulesMixin";
import DictionaryAliasInput from "@/components/dictionaries/DictionaryAliasInput.vue";
import TagService from "@/services/TagService";
import { CreateTag, UpdateTag } from "@/entity/Tag";

@Component({
  components: {
    FormCard,
    DictionaryAliasInput,
  },
})
export default class TagForm extends Mixins(BaseForm, RulesMixin) {
  data: CreateTag | UpdateTag = {
    name: "",
    description: "",
  };

  async fetchIndustry(): Promise<void> {
    if (this.entityId) {
      const response = await TagService.findOne(this.entityId);
      this.data.name = response.name;
      this.data.description = response.description;
    }
  }

  async submitForm(): Promise<void> {
    if (this.entityId) {
      await TagService.update(this.entityId, this.data);
    } else {
      await TagService.create(this.data);
    }
  }

  created() {
    this.handleFormLoad(this.fetchIndustry);
  }
}
